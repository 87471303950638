<template>
  <div>
    <v-dialog
      v-if="!error && data"
      v-model="showPreviewDialog"
      max-width="75%"
    >
      <v-card elevation="24">
        <v-row class="no-gutters" style="position: relative;">
          <div v-if="audio_levels" style="position: absolute;  width: 80%; margin: 10px;">
            <div v-for="channel in audio_levels" v-bind:key="channel.channel_num">
              <v-progress-linear
                height="2"
                color="red"
                class="my-1"
                :value="getAudioLevel(channel.channel_num)"
              />
            </div>
          </div>
          
          <img v-if="thumbnail" v-bind:src="'data:image/jpeg;base64,' + thumbnail" style="width: 100%; box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.1);" @click="showPreviewDialog = false" />
          
          <div v-if="thumbnail && !hasFocus" style="position: absolute; width: 100%; text-align: center; bottom: 45%; text-shadow: 1px 1px 2px black;" class="caption white--text">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="white"
                  dark
                  size="72"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-pause
                </v-icon>
              </template>
              <span>Preview may be paused if the window does not have focus.</span>
            </v-tooltip>
          </div>
          
          <div style="position: absolute; width: 100%; text-shadow: 1px 1px 2px black; bottom: 10px; text-align: center;" class="caption white--text">
            This is a scaled preview image, not representative of stream output.
          </div>
        </v-row>
      </v-card>
    </v-dialog>
    
    <v-row 
      v-if="error"
      style="position: relative;"
      class="no-gutters my-1"
    >
      <div class="px-3 pt-5 red lighten-5" style="width: 320px; height: 180px; box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.1); text-align: center;">
        <v-icon size="72" color="red" class="mb-3">tv_off</v-icon>
        <p class="text--disabled">{{ errorText }}</p>
      </div>
    </v-row>
    
    <v-row 
      v-else-if="!data"
      style="position: relative;"
      class="no-gutters my-1"
    >
      <v-progress-linear
        class="ma-5"
        color="primary"
        indeterminate
        buffer-value="0"
      ></v-progress-linear>
    </v-row>
    
    <v-row 
      v-else-if="!big"
      style="position: relative;"
      class="no-gutters my-1"
    >
      <img v-if="thumbnail" v-bind:src="'data:image/jpeg;base64,' + thumbnail" class="mr-6" style="max-width: 45%; box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.1); cursor: pointer;" @click="showPreviewDialog = true" />
      
      <div v-else class="px-5 pt-5 red lighten-5" style="box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.1); text-align: center;">
        <v-icon size="72" color="red" class="mb-3">tv_off</v-icon>
        <p class="text--disabled">Thumbnail data not received...</p>
      </div>
      
      <div v-if="audio_levels" style="min-width: 45%;">
        <h5>Audio Levels</h5>
        <div v-for="channel in audio_levels" v-bind:key="channel.channel_num">
          <v-progress-linear
            height="2"
            color="red"
            class="my-1"
            :value="getAudioLevel(channel.channel_num)"
          />
        </div>
      </div>
      
      <div v-if="thumbnail && !hasFocus" style="position: absolute; text-shadow: 1px 1px 2px black;" class="caption white--text pa-3">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="white"
              dark
              v-bind="attrs"
              v-on="on"
            >
              mdi-pause
            </v-icon>
          </template>
          <span>Preview may be paused if the window does not have focus.</span>
        </v-tooltip>
      </div>
    </v-row>
    
    <v-row v-else class="no-gutters mt-1">
      <div v-if="audio_levels" style="position: absolute;  width: 80%; margin: 10px;">
        <div v-for="channel in audio_levels" v-bind:key="channel.channel_num">
          <v-progress-linear
            height="2"
            color="red"
            class="my-1"
            :value="getAudioLevel(channel.channel_num)"
          />
        </div>
      </div>
      
      <img v-if="thumbnail" v-bind:src="'data:image/jpeg;base64,' + thumbnail" class="mr-6" style="width: 320px; box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.1); cursor: pointer;" @click="showPreviewDialog = true"/>
      
      <div v-else class="px-5 pt-8 red lighten-5" style="width: 320px; height: 180px; box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.1); text-align: center;">
        <v-icon size="72" color="red" class="mb-3">tv_off</v-icon>
        <p class="text--disabled">Thumbnail data not received...</p>
      </div>
      
      <div v-if="thumbnail && !hasFocus" style="position: absolute; text-shadow: 1px 1px 2px black; margin: 10px;" class="caption white--text">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="white"
              dark
              v-bind="attrs"
              v-on="on"
            >
              mdi-pause
            </v-icon>
          </template>
          <span>Preview may be paused if the window does not have focus.</span>
        </v-tooltip>
      </div>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'InputPreview',
    
    props: ['device_guid', 'input_id', 'big'],
    
    data() {
      return {
        inputPreviewSupportedVersion: this.$helpers.parseVersion('cloud', 'v1.3.0'),
        
        error: false,
        errorText: '',
        
        subscription: false,
        
        data: false,
        
        audio_levels: false,
        thumbnail: false,
        
        requestInterval: 10000,
        requestTimer: false,
        
        hasFocus: true,
        showPreviewDialog: false,
        
        destroyed: false,
      }
    },
    
    computed: {
      previewTopic() {
        return 'dt/videon_cloud/device/' + this.device_guid + '/input_preview'
      },
      pubsub() {
        return this.$Amplify.PubSub
      },
      device() {
        return this.$devices.getDevice(this.device_guid)
      },
      isPreviewSupported() {
        if (this.$helpers.versionCheck(this.inputPreviewSupportedVersion, this.device.cloud_version) || this.device.cloud_version.dev) {
          return true
        }
        return false
      },
    },
    
    mounted() {
      setTimeout(() => {
        this.setupPreview()
      }, 1500)
    },
    
    beforeDestroy() {
      this.destroyed = true
      
      clearTimeout(this.requestTimer)
      
      if (this.subscription) {
        console.log('InputPreview ' + this.device_guid + ' unsubscribe ' + this.previewTopic)
        this.subscription.unsubscribe()
      }
    },
    
    methods: {
      setupPreview() {
        // handle all the error logic here...
        if (!this.device.isOnline()) {
          this.error = true
          this.errorText = 'Input preview unavailable when the device is offline.'
        } else if (!this.isPreviewSupported) {
          this.error = true
          this.errorText = 'Input preview requires cloud agent version ' + this.inputPreviewSupportedVersion.original + '.'
        } else if (!this.device.hasFeature('daemon.encoders.video.input_preview.enabled')) {
          this.error = true
          this.errorText = 'Input preview is not licensed.'
        } else {
          this.subscribeToTopic()
          this.requestPreview(true)
        }
      },
      
      subscribeToTopic() {
        if (!this.device_guid) {
          return
        }
        
        if (!this.subscription) {
          console.log('InputPreview ' + this.device_guid + ' subscribeToTopic ' + this.previewTopic)
          
          this.subscription = this.pubsub.subscribe(this.previewTopic).subscribe({
            next: (data) => { 
              if (data.value) {
                this.data = data.value
                
                // filter on input_id
                if (!this.input_id || (this.data.input_id == this.input_id)) {
                  if (this.data.audio_levels) {
                    this.audio_levels = this.data.audio_levels
                  }
                  if (this.data.thumbnail) {
                    this.thumbnail = this.data.thumbnail
                  }
                }
                
                // reset error
                this.error = false
                this.errorText = ''
              }
            },
            error: (error) => {
              console.log('InputPreview ' + this.device_guid + ' subscribeToTopic Error:', error)
              this.error = true
              this.errorText = this.$helpers.parseError(error)
            },
            complete: () => {
              console.log('InputPreview ' + this.device_guid + ' subscribeToTopic complete')
            }
          })
        }
      },
      
      async requestPreview(firstRun = false) {
        this.hasFocus = document.hasFocus()
        
        if (!firstRun && !this.data && document.hasFocus()) {
          this.error = true
          this.errorText = 'Preview data not received within ' + (this.requestInterval / 1000) + ' seconds.  Please check thumbnail output settings and verify they are compatible with input preview.'
        }
        
        let requestSuccess = await this.device.requestInputPreview(firstRun)
        
        if (!requestSuccess) {
          this.error = true
          this.errorText = 'Input preview request failed...'
        }
        
        this.requestTimer = setTimeout(() => {
          if (!this.destroyed) {
            this.requestPreview()
          }
        }, this.requestInterval)
      },
      
      getAudioLevel(channelNumber) {
        var channel = this.audio_levels.find(x => x.channel_num === channelNumber)
        if (channel && channel.max_hold_200ms > 0) {
          return channel.max_hold_200ms / 300
        }
        return 0
      }
      
    }
  }
</script>