<template>
  <v-alert dark dense rounded class="alert-chip ma-1 darken-1" :outlined="outlined" :color="chipColor">
    <span class="text-no-wrap">
      <span class="text-capitalize font-weight-medium">{{ eventData.to_state.label || eventData.from_state.label || 'Missing Label' }}:</span>
    </span>&nbsp;
    
    <span class="text-no-wrap">
      <span class="text-capitalize font-weight-medium">Alert Condition:</span>
      <span v-if="Object.keys(eventData.to_state).length > 0">&nbsp;{{ $helpers.deviceAlertCondition(eventData.to_state) }}</span>
      <span v-else>&nbsp;{{ $helpers.deviceAlertCondition(eventData.from_state) }}</span>
    </span>&nbsp;
    
    <span class="text-no-wrap">
      <span class="text-capitalize font-weight-medium">Status:</span>
      <span v-if="eventData.to_state.resolved == true">&nbsp;Resolved</span>
      <span v-else-if="eventData.to_state.resolved == false">&nbsp;Unresolved</span>
      <span v-else>&nbsp;{{ eventData.message }}</span>
    </span>&nbsp;
    
    <span v-if="eventData.to_state.silenced == true" class="text-no-wrap">
      <span class="text-capitalize font-weight-medium">Silenced By:</span>
      <span>&nbsp;{{ $users.getUser(eventData.to_state.silenced_toggled_by).displayName() }}</span>
      &nbsp;
    </span>
    
    <span v-if="eventData.to_state.closed_by && eventData.to_state.closed_by == 'alert_disabled'" class="text-no-wrap">
      <span class="text-capitalize font-weight-medium">Alert Disabled</span>
    </span>
    <span v-else-if="eventData.to_state.closed_by && eventData.to_state.closed_by == 'shadow_removal'" class="text-no-wrap">
      <span class="text-capitalize font-weight-medium">Alert Config Removed</span>
    </span>
    <span v-else-if="eventData.to_state.closed_by && eventData.to_state.closed_by != 'auto'" class="text-no-wrap">
      <span class="text-capitalize font-weight-medium">Closed By:</span>
      <span>&nbsp;{{ $users.getUser(eventData.to_state.closed_by).displayName() }}</span>
    </span>
    
  </v-alert>
</template>

<script>
  
  export default {
    name: 'DeviceAlertEvent',
    props: {
      eventData: {
        type: Object,
        default: () => {},
      },
    },
    computed: {
      chipColor() {
        if (this.eventData.to_state.closed_by || (this.eventData.to_state.alert_state && this.eventData.to_state.alert_state == 'ok')) {
          return 'green'
        } else if (this.eventData.to_state.silenced) {
          return 'blue'
        } else if (Object.keys(this.eventData.to_state).length == 0) {
          return 'grey'
        }
        return 'red'
      },
      outlined() {
        if (
          Object.keys(this.eventData.to_state).length == 0 
          || Object.keys(this.eventData.from_state).length == 0  
          || this.eventData.to_state.resolved == true 
          || this.eventData.to_state.closed_by
          || (this.eventData.to_state.alert_state && this.eventData.to_state.alert_state == 'ok')
        ) {
          return false
        }
        
        return true
      }
    },
    methods: {
      conditionLabel(condition) {
        if (condition == 'EqualToThreshold') {
          return '='
        } else if (condition == 'NotEqualToThreshold') {
          return '!='
        } else if (condition == 'GreaterThanThreshold') {
          return '>'
        } else if (condition == 'LessThanThreshold') {
          return '<'
        }
        return ' '
      },
    }
  }
</script>
<style lang="css" scoped>
  .alert-chip {
     font-size: 12px;
     line-height: 20px;
     padding: 3px 12px;
     min-height: 24px;
     align-items: center;
     display: inline-flex;
  }
</style>