import Vue from 'vue'
import moment from 'moment'

class VideonLegacyAlert {
  constructor(alert_guid, alert_attributes = false) {
    this.alert_guid = alert_guid
    this.ready = false
    this.alert_type = 'legacy'
    
    if (alert_attributes) {
      this.device_guid = alert_attributes.device_guid
      this.timestamp = alert_attributes.timestamp
      this.last_status_change = alert_attributes.last_status_change
      
      this.alert_type = alert_attributes.alert_type
      
      this.name = alert_attributes.name
      
      this.threshold_value = alert_attributes.threshold_value
      this.threshold_condition = alert_attributes.threshold_condition
      
      this.resolved = alert_attributes.resolved
      
      this.silenced = alert_attributes.silenced
      this.silenced_toggled_by = alert_attributes.silenced_toggled_by
      this.silenced_timestamp = alert_attributes.silenced_timestamp
      
      // handle label
      if (alert_attributes.label) {
        this.label = alert_attributes.label
      } else {
        this.label = Vue.helpers.alertCondition(this)
      }
      
      this.ready = true
    }
  }
}

class VideonDeviceAlert {
  constructor(alert_guid, alert_attributes = false) {
    this.alert_guid = alert_guid
    this.ready = false
    this.alert_type = 'device'
    this.config = {}
    
    if (alert_attributes) {
      this.date = alert_attributes.date
      this.device_guid = alert_attributes.device_guid
      this.timestamp = alert_attributes.timestamp
      this.category = alert_attributes.category
      this.type = alert_attributes.type
      this.name = alert_attributes.name
      this.label = alert_attributes.label
      this.alert_state = alert_attributes.alert_state
      
      this.config.threshold_value = alert_attributes.config.threshold_value
      this.config.threshold_condition = alert_attributes.config.threshold_condition
      
      this.silenced = alert_attributes.silenced
      this.silenced_toggled_by = alert_attributes.silenced_toggled_by
      this.silenced_timestamp = alert_attributes.silenced_timestamp
      
      this.ready = true
    }
  }
}


export default new class VideonAlerts {
  constructor() {
    this.org_guid = false
    this.alerts = []
    
    this.ready = false
    this.error = false
    this.errorText = ''
    
    this.paginationTokens = []
    this.itemsPerPage = 50
    
    this.backgroundRefresh = false // ignore document.hasFocus check... This may be useful for audible sounds
    
    this.refreshAlerts = true
    this.refreshInterval = 10000
    this.refreshTimer = false
  }
  
  reset() {
    console.log('VideonAlerts reset')
    this.org_guid = false
    this.alerts.splice(0)
  }
  
  async getAlerts(org_guid) {
    if (!org_guid) {
      return
    }
    
    if (!this.ready || this.backgroundRefresh || document.hasFocus()) {
      this.refreshInterval = Vue.helpers.getRefreshInterval(8000, 15000)
      
      this.ready = false
      this.org_guid = org_guid
      
      this.error = false
      this.errorText = ''
      
      var newAlerts = []
    
      // TODO maybe revisit the time window for this
      var now = moment()
      var startTime = now.subtract('1', 'month').toISOString()
    
      var queryParams = {
        'org_guid': this.org_guid,
        'pagination_size': this.itemsPerPage,
        'start_time': startTime
      }
      
      // Legacy Alerts
      var paginationToken = false
      try {
        do {
          if (paginationToken) {
            queryParams.pagination_token = paginationToken
          }
          
          let response = await Vue.axios.get('/alerts', {'params': queryParams})
          
          if (response.data.alerts.length > 0) {
            newAlerts = newAlerts.concat(response.data.alerts.map((thisAlert) => {
              return Vue.observable(new VideonLegacyAlert(thisAlert.alert_guid, thisAlert))
            }))
            
            if (response.data.pagination_token) {
              paginationToken = response.data.pagination_token
            } else {
              paginationToken = false
            }
          }
        } while (paginationToken)
      } catch (error) {
        console.log('VideonAlert getAlerts (legacy) error', error)
        this.error = true
        this.errorText =  'Unable to fetch current legacy alerts due to "' + Vue.helpers.parseError(error) + '"'
      }
      
      // Device Alerts
      paginationToken = false
      try {
        do {
          if (paginationToken) {
            queryParams.pagination_token = paginationToken
          }
          
          let response = await Vue.axios.get('/device_alerts', {'params': queryParams})
          
          if (response.data.alerts.length > 0) {
            newAlerts = newAlerts.concat(response.data.alerts.map((thisAlert) => {
              return Vue.observable(new VideonDeviceAlert(thisAlert.alert_guid, thisAlert))
            }))
            
            if (response.data.pagination_token) {
              paginationToken = response.data.pagination_token
            } else {
              paginationToken = false
            }
          }
        } while (paginationToken)
      } catch (error) {
        console.log('VideonAlert getAlerts (device) error', error)
        this.error = true
        this.errorText =  'Unable to fetch current device alerts due to "' + Vue.helpers.parseError(error) + '"'
      }
      
      // test
      //newAlerts.push(new VideonAlert(1234, {'device_guid': '47677e9d-403b-4470-a56c-f87ab72f1907'}))
      
      // swap out our new alerts array
      this.alerts = newAlerts
      this.ready = true
    }
    
    if (this.refreshAlerts) {
      clearTimeout(this.refreshTimer)
      
      this.refreshTimer = setTimeout(() => {
        this.getAlerts(this.org_guid)
      }, this.refreshInterval)
    }
    
    return this.alerts
  }
  
  hasAlerts() {
    if (this.alertCount() > 0 || this.error) {
      return true
    }
    return false
  }
  
  alertCount() {
    if (this.error) {
      return '!'
    }
    
    var visibleAlerts = 0
    for (var index in this.alerts) {
      if (!this.alerts[index].resolved && !this.alerts[index].silenced) {
        visibleAlerts++
      }
    }
    
    return visibleAlerts
  }
  
  unresolvedAlerts() {
    var alerts = []
    for (var index in this.alerts) {
      if (!this.alerts[index].resolved) {
        alerts.push(this.alerts[index])
      }
    }
    return alerts
  }
  
  alertsForDevice(device_guid) {
    var alerts = []
    for (var index in this.alerts) {
      if (this.alerts[index].device_guid == device_guid && !this.alerts[index].resolved) {
        alerts.push(this.alerts[index])
      }
    }
    return alerts
  }
}