<template>
  <v-container>
    <v-toolbar
      flat
      dense
      :color="($vuetify.theme.dark) ? '' : 'grey lighten-5'"
    >
      <span class="subtitle-2 mr-3">Actions</span>
      
      <v-btn
        v-if="container.status == 'created' || container.status == 'exited'"
        outlined
        x-small
        class="ma-1"
        :disabled="!canEdit || container.status == 'paused' || !isOnline || isProcessing || isProtectedContainer(container)"
        @click="containerAction(container, 'start')"
      >
        Start
      </v-btn>
      
      <v-btn
        v-if="container.status == 'running' || container.status == 'restarting'"
        outlined
        x-small
        class="ma-1"
        :disabled="!canEdit || container.status == 'paused' || !isOnline || isProcessing || isProtectedContainer(container)"
        @click="containerAction(container, 'stop')"
      >
        Stop
      </v-btn>
      
      <v-btn
        v-if="container.status == 'running'"
        outlined
        x-small
        class="ma-1"
        :disabled="!canEdit || container.status == 'paused' || !isOnline || isProcessing || isProtectedContainer(container)"
        @click="containerAction(container, 'pause')"
      >
        Pause
      </v-btn>
      
      <v-btn
        v-if="container.status == 'paused'"
        outlined
        x-small
        class="ma-1"
        :disabled="!canEdit || container.status == 'running' || !isOnline || isProcessing || isProtectedContainer(container)"
        @click="containerAction(container, 'unpause')"
      >
        Resume
      </v-btn>
      
      <v-btn
        outlined
        x-small
        class="ma-1"
        :disabled="!canEdit || container.status != 'running' || !isOnline || isProcessing"
        @click="containerAction(container, 'restart')"
      >
        Restart
      </v-btn>
      
      <v-spacer />
      
      <v-btn
        outlined
        x-small
        class="ma-1"
        :disabled="!canEdit || (container.status != 'running' && container.status != 'restarting') || !isOnline || isProcessing || isProtectedContainer(container)"
        @click="containerAction(container, 'kill')"
      >
        Kill
      </v-btn>
      
      <v-btn
        v-if="isDockerLogSessionSupported"
        outlined
        x-small
        class="ma-1"
        @click="restartSession"
        color="secondary"
      >
        Reload Logs
      </v-btn>
    </v-toolbar>
    
    <hr class="" />
    
    <div
      class="black white--text pa-3 mb-3 pb-4"
      style="font-family: 'Roboto Mono'; white-space: pre-wrap; height: 480px; max-height: 480px; overflow: auto;"
      ref="logViewer"
      v-html="logContent"
    ></div>
    
    <div v-if="canEdit && showDeleteContainer" class="text-right">
      <v-btn
        class="ma-1 d-none d-md-inline"
        x-small
        outlined
        @click="deleteContainer(container)"
        :disabled="!canEdit || (container.status != 'exited' && container.status != 'created') || !isOnline || isProcessing || isProtectedContainer(container)"
      >
        Delete Container
      </v-btn>
    </div>
  </v-container>
</template>

<script>
  import { AnsiUp } from 'ansi_up'
  const ansi_up = new AnsiUp()
  
  export default {
    name: 'ManageContainer',
    
    props: [
      'device_guid', 
      'container', 
      'canEdit',
      'isOnline',
      'isProcessing',
      'actionedContainer',
      'showDeleteContainer',
    ],
    
    data() {
      return {
        dockerLogSupportedVersion: this.$helpers.parseVersion('cloud', 'v1.23.0'),
        dockerLogSessionsVersion: this.$helpers.parseVersion('cloud', 'v1.25.0'),
        
        error: false,
        errorText: '',
        
        session_id: this.$helpers.uuidv4(),
        
        // number of lines of history to include
        lines: 100, 
        
        subscription: false,
        
        logs: '',
        
        requestInterval: 10000,
        requestTimer: false,
        
        hasFocus: true,
        
        protectedContainerNames: [
          'videon-supervisor',
          'videon-fans',
          'videon-cloud',
          'videon-api-app',
          'videon-api-proxy'
        ],
        
        destroyed: false,
      }
    },
    
    computed: {
      logsTopic() {
        if (this.isDockerLogSessionSupported) {
          return 'dt/videon_cloud/device/' + this.device_guid + '/container_logs/' + this.session_id
        }
        
        return 'dt/videon_cloud/device/' + this.device_guid + '/container_logs/' + this.container.id
      },
      
      pubsub() {
        return this.$Amplify.PubSub
      },
      
      device() {
        return this.$devices.getDevice(this.device_guid)
      },
      
      isDockerLogSupported() {
        if (this.$helpers.versionCheck(this.dockerLogSupportedVersion, this.device.cloud_version) || this.device.cloud_version.dev) {
          return true
        }
        return false
      },
      
      isDockerLogSessionSupported() {
        if (this.$helpers.versionCheck(this.dockerLogSessionsVersion, this.device.cloud_version)) {
          return true
        }
        return false
      },
      
      logContent() {
        if (!this.isDockerLogSupported) {
          return 'Docker logging support requires newer software. Please update your cloud agent...'
        } else if (!this.logs) {
          return 'Waiting for log stream...'
        }
        return this.logs
      }
    },
    
    mounted() {
      this.subscribeToTopic()
    },
    
    beforeDestroy() {
      this.destroyed = true
      
      clearTimeout(this.requestTimer)
      
      if (this.subscription) {
        console.log('ManageContainer ' + this.device_guid + ' unsubscribe ' + this.logsTopic)
        this.subscription.unsubscribe()
      }
    },
    
    methods: {
      subscribeToTopic() {
        if (!this.device_guid) {
          return
        }
        
        if (!this.subscription) {
          console.log('ManageContainer ' + this.device_guid + ' subscribeToTopic ' + this.logsTopic)
          
          this.subscription = this.pubsub.subscribe(this.logsTopic).subscribe({
            next: (data) => {
              if (data.value) {
                var newLine = ansi_up.ansi_to_html(data.value)
                
                this.logs = this.logs + newLine
                
                // scroll logViewer to the bottom
                setTimeout(() => {
                  var logViewer = this.$refs.logViewer
                  if (logViewer) {
                    logViewer.scrollTop = logViewer.scrollHeight
                  }
                }, 100)
              }
            },
            error: (error) => {
              console.log('ManageContainer ' + this.device_guid + ' subscribeToTopic Error:', error)
              this.error = true
              this.errorText = this.$helpers.parseError(error)
            },
            complete: () => {
              console.log('ManageContainer ' + this.device_guid + ' subscribeToTopic complete')
            }
          })
          
          setTimeout(() => {
            this.requestLogs()
          }, 1000)
        }
      },
      
      async requestLogs() {
        var commandData = {
          'session_id': this.session_id,
          'container_id': this.container.id, 
          'duration': 15,
          'lines': this.lines
        }
        
        var commandObject = {'command': 'send_docker_logs', 'data': commandData}
        
        try {
          let response = await this.axios.post('/devices/' + this.device_guid + '/commands', commandObject)
          console.log('ManageContainer ' + this.device_guid + ' requestLogs response', response)
        } catch (error) {
          console.log('ManageContainer ' + this.device_guid + ' requestLogs error', this.$helpers.parseError(error))
        }
        
        this.requestTimer = setTimeout(() => {
          if (!this.destroyed) {
            this.requestLogs()
          }
        }, this.requestInterval)
      },
      
      restartSession() {
        this.logs = ''
        this.session_id = this.$helpers.uuidv4()
        
        clearTimeout(this.requestTimer)
        
        if (this.subscription) {
          this.subscription.unsubscribe()
          this.subscription = false
        }
        
        this.subscribeToTopic()
      },
      
      isProtectedContainer(item) {
        if (item.name && this.protectedContainerNames.includes(item.name)) {
          return true
        }
        return false
      },
      
      containerAction(container, action) {
        this.$emit('containerAction', container, action)
      },
      
      deleteContainer(container) {
        this.$emit('deleteContainer', container)
      }
      
    }
  }
</script>