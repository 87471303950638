import Vue from 'vue'

class VideonBanner {
  constructor(banner_id, banner_attributes = false) {
    this.banner_id = banner_id
    this.ready = false
    
    this.title = ''
    this.html = ''
    this.icon = ''
    this.color = 'accent'
    
    if (banner_attributes) {
      for (var property in banner_attributes) {
        this[property] = banner_attributes[property]
      }
      this.ready = true
    }
    
    this.error = false
    this.errorText = ''
    
    if (!this.ready) {
      this.fetchBanner()
    }
  }
  
  fetchBanner() {
    // hardcoded for first release
    this.title = ''
    this.html = ''
    this.icon = 'mdi-bullhorn'
  }

}

export default new class Banners {
  constructor() {
    this.banners = []
  }
  
  defaultBanner() {
    return false
  }
  
  getBanner(banner_id, banner_attributes = false) {
    if (!banner_id) {
      console.log('Banners getBanner missing banner_id', banner_id, banner_attributes)
      return false
    }
    
    var banner = this.banners.find(x => x.banner_id === banner_id)
    if (banner) {
      return banner
    }
    
    banner = Vue.observable(new VideonBanner(banner_id, banner_attributes))
    this.banners.push(banner)
    
    return banner
  }
}