<template>
  <v-container fluid class="pa-3 pt-5 pa-md-8 pt-md-6">
    <v-row>
      <h1 class="display-1 text--disabled ma-2 mb-5">{{ orgName }}</h1>
    </v-row>
    <div>
      <v-row>
        <v-progress-linear
          v-if="loading"
          class="ma-5"
          color="primary"
          indeterminate
          buffer-value="0"
        ></v-progress-linear>
        <device-status-tiles v-if="!loading" :status="orgStatus" :useAlerts="true" />
      </v-row>
      
      <v-row v-if="$alerts.unresolvedAlerts().length > 0">
        <v-col rows="12">
          <videon-card heading="Active Alerts" flat>
            <v-card-text>
              <v-data-table
                :headers="alertHeaders"
                :items="$alerts.unresolvedAlerts()"
                sort-by="timestamp"
                :sort-desc="true"
                hide-default-footer
                :items-per-page="1000"
              >
                <template v-slot:item.label="{ item }">
                  <span class="subtitle-2">{{ item.label }}</span>
                </template>
                <template v-slot:item.resolved="{ item }">
                  <alert-state-label :alert="item" />
                </template>
                <template v-slot:item.device_guid="{ item }">
                  <device-label :device="$devices.getDevice(item.device_guid)" />
                </template>
                <template v-slot:item.timestamp="{ item }">
                  {{ item.timestamp | moment("YYYY/MM/DD hh:mm:ss a") }}
                </template>
              </v-data-table>
            </v-card-text>
          </videon-card>
        </v-col>
      </v-row>
      
      <v-row v-if="streamingDevices.length > 0">
        <v-col rows="12">
          <videon-card heading="Active Streams" flat>
            <v-card-text>
              <device-list v-model="streamingDevices" :loading="loading"/>
            </v-card-text>
          </videon-card>
        </v-col>
      </v-row>
      
      <v-row>
        <v-col cols="12" sm="12">
          <videon-card heading="Recent Alerts" flat>
            <device-events heading="Recent Device Alerts" :showDevice="true" targetObject="orgs" :targetGUID="organization.org_guid" :startTime="startTime" eventType="device_alert" :eventLimit="5"/>
          
            <device-events heading="Recent Legacy Alerts" :showDevice="true" targetObject="orgs" :targetGUID="organization.org_guid" :startTime="startTime" eventType="alert" :eventLimit="5"/>
          </videon-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <videon-card heading="Recent Organization Events" flat>
            <device-events :showDevice="true" targetObject="orgs" :targetGUID="organization.org_guid" :startTime="startTime" :eventLimit="10"/>
          </videon-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
  import { mapGetters } from "vuex"
  
  import DeviceList from '../components/device/components/DeviceList.vue'
  import DeviceEvents from '../components/device/components/DeviceEvents.vue'
  
  import moment from 'moment'
  
  export default {
    components: {
      DeviceList,
      DeviceEvents
    },
    data() {
      return {
        error: false,
        errorText: '',
        
        loading: true,
        
        orgStatus: false,
        
        //onlineDevices: [],
        streamingDevices: [],
        
        alertHeaders: [
          { text: 'Device', align: 'start text-no-wrap', value: 'device_guid', sortable: false },
          { text: 'Alert', align: 'start', value: 'label', sortable: true, width: '25%' },
          { text: 'Created', align: 'start', value: 'timestamp', sortable: false },
          { text: 'State', align: 'start', value: 'resolved', sortable: false},
          
        ],
        
        startTime: moment().subtract('24', 'hour').toISOString(),
        
        refreshInterval: 10000,
        refreshTimer: false,
      }
    },
    computed: {
      ...mapGetters('user', ['user', 'organization', 'organizations']),
      
      orgName() {
        if (this.organization && this.organization.org_name) {
          return this.organization.org_name
        }
        return 'Unknown Org'
      },
    },
    mounted() {
      this.fetchOrgStatus()
    },
    beforeDestroy() {
      if (this.refreshTimer) {
        clearTimeout(this.refreshTimer)
        this.refreshTimer = false
      }
    },
    methods: {
      fetchOrgStatus() {
        if (this.loading || document.hasFocus()) {
          this.axios.get('/devices/status', {params: {org_guid: this.organization.org_guid}})
          .then((response) => {
            console.log('Dashboard fetchOrgStatus response', response)
        
            this.orgStatus = response.data.status
            
            //this.onlineDevices = this.orgStatus.devices_online.map((device_guid) => this.$devices.getDevice(device_guid))
            this.streamingDevices = this.orgStatus.devices_streaming.map((device_guid) => this.$devices.getDevice(device_guid))
            
            this.loading = false
          }).catch((error) => {
            this.error = true
            this.errorText = this.$helpers.parseError(error)
            this.loading = false
          })
        }
        
        this.refreshTimer = setTimeout(() => {
          this.fetchOrgStatus()
        }, this.refreshInterval)
      }
    }
    
  
  }
</script>
