import Vue from 'vue'
import store from '../store'

class VideonDeviceShadow {
  constructor(device_guid, shadow_name, shadow_attributes = false) {
    this.device_guid = device_guid
    this.shadow_name = shadow_name
    
    this.editing = false
    
    this.fetching = false
    this.requesting = false
    this.updatePending = false
    this.requestPending = false
    
    this.reported = false
    this.requested = false
    
    this.shadowDiff = false
    
    this.commandResponseCheckInterval = 2000
    this.commandResponseCheckMaxAttempts = 10
    this.commandResponseCheckAttempt = 0
    this.commandResponseTimer = false
    this.commandGUID = ''
    
    this.ready = false
    
    this.info = false
    this.infoText = ''
    
    this.error = false
    this.errorText = ''
        
    if (shadow_attributes) {
      this.ready = true
    } else {
      this.fetchShadow()
    }
  }
  
  canRequestShadowFromDevice() {
    if (this.shadow_name != 'Alerts') {
      return true
    }
    return false
  }
  
  state() {
    if (this.reported && ((typeof this.reported.state === 'object' && !Array.isArray(this.reported.state) && this.reported.state !== null) || this.reported.state.length > 0)) {
      return this.reported.state
    } else if (this.requested && this.requested.state.length > 0) {
      return this.requested.state
    }
    return false 
  }
  
  version() {
    if (this.reported) {
      return this.reported.current_version
    }
    return 0
  }
  
  editableStateCopy() {
    return JSON.parse(JSON.stringify(this.state()))
  }
  
  resetError() {
    this.error = false
    this.errorText = ''
  }
  
  fetchShadow(force = false, skipRequested = true, attempt = 0) {
    // stop multiple concurrent requests
    if (this.fetching == true && !force) {
      console.log('VideonDeviceShadow ' + this.device_guid + ' ' + this.shadow_name + ' v' + this.version() + ' fetchShadow aborted (already fetching)')
      return
    }
    
    this.ready = false
    this.fetching = true
    
    Vue.axios.get('/devices/' + this.device_guid + '/shadows', {
      'params': {
        'shadow_names': this.shadow_name,
        'skip_requested_state': skipRequested
      }
    }).then((response) => {
      this.fetching = false
      
      if (response.data.shadows[0]) {
        if (response.data.shadows[0].reported) {
          console.log('VideonDeviceShadow ' + this.device_guid + ' ' + this.shadow_name + ' v' + this.version() + ' fetchShadow response version updated to ' + response.data.shadows[0].reported.current_version)
          this.updatePending = false
        }
        
        this.reported = (response.data.shadows[0].reported) ? response.data.shadows[0].reported : false
        this.requested = (response.data.shadows[0].requested) ? response.data.shadows[0].requested : false
        
        if ((!this.reported || this.reported.state.length == 0) && this.requested) {
          this.requestPending = true
        }
        
        console.log('VideonDeviceShadow ' + this.device_guid + ' ' + this.shadow_name + ' v' + this.version() + ' fetchShadow response', response)
        
        if ((!this.reported || this.reported.state.length == 0) && this.canRequestShadowFromDevice()) {
          this.requestShadowUpdate()
        } else if ((!this.reported || this.reported.state.length == 0) && !this.requested && attempt < 1) {
          // increment attempt and refetch with requested state to see if we have a pending shadow
          attempt += 1
          this.fetchShadow(false, false, attempt)
        } else {
          if (!this.commandGUID) {
            this.ready = true
          }
        }
      } else {
        console.log('VideonDeviceShadow ' + this.device_guid + ' ' + this.shadow_name + ' v' + this.version() + ' fetchShadow missing state', response)
        
        this.error = true
        this.errorText = 'Missing Shadow State'
      }
      
      // update device properties...
      Vue.devices.getDevice(this.device_guid).refreshProperties()
    }).catch((error) => {
      this.error = true
      this.errorText = Vue.helpers.parseError(error)
      if (!this.commandGUID) {
        this.ready = true
      }
    })
  }
  
  requestShadowUpdate() {
    if (!Vue.devices.getDevice(this.device_guid).isOnline()) {
      console.log('VideonDeviceShadow ' + this.device_guid + ' ' + this.shadow_name + ' v' + this.version() + ' requestShadowUpdate, but device is offline')
      this.ready = true
      return
    }
    
    this.ready = false
    this.requesting = true
    
    var shadowCommand = {
      'command_type': 'get',
      'commands': [
        {
          'shadow_name': this.shadow_name
        }
      ]
    }
    
    Vue.axios.post('/devices/' + this.device_guid + '/shadows/commands', shadowCommand)
    .then((response) => {
      console.log('VideonDeviceShadow ' + this.device_guid + ' ' + this.shadow_name + ' v' + this.version() + ' requestShadowUpdate response', response)
      
      if (response.data.commands[0] && response.data.commands[0].command_guid) {
        this.commandGUID = response.data.commands[0].command_guid
        
        this.commandResponseCheckAttempt = 0
        this.commandResponseTimer = setTimeout(() => {
          this.checkShadowCommand()
        }, this.commandResponseCheckInterval)
      }
      
    }).catch((error) => {
      this.error = true
      this.errorText = Vue.helpers.parseError(error)
    }) 
  }
  
  checkShadowCommand() {
    if (!this.commandGUID) {
      return
    }
    
    Vue.axios.get('/devices/' + this.device_guid + '/shadows/commands/' + this.commandGUID)
    .then((response) => {
      console.log('VideonDeviceShadow ' + this.device_guid + ' ' + this.shadow_name + ' v' + this.version() + ' checkShadowCommand response', response)
      
      if (response.data.command.finished === true) {
        console.log('VideonDeviceShadow ' + this.device_guid + ' ' + this.shadow_name + ' v' + this.version() + ' checkShadowCommand finished', response.data.command)
        
        // need to change this after the agent change...
        setTimeout(() => {
          this.fetchShadow()
        }, 10000)
        
        // reset command guid
        this.commandGUID = ''
        this.requesting = false
        
        if (response.data.command.command_state == 'succeeded') {
          // wheeeee
        } else {
          // handle Config mismatch! differently
          if (
            response.data.command.response.message == 'Config mismatch!'
            && response.data.command.requested.state
            && response.data.command.response.state
          ) {
            // do an actual diff, if there are no changes we care about, dont throw an error
            this.shadowDiff = Vue.helpers.shadowConfigDiff(response.data.command.requested.state, response.data.command.response.state)
            if (this.shadowDiff && this.shadowDiff.length == 0) {
              console.log('VideonDeviceShadow ' + this.device_guid + ' ' + this.shadow_name + ' v' + this.version() + ' checkShadowCommand silently handling Config Mismatch! due to diff results with ignored keys', Vue.helpers.shadowConfigDiff(response.data.command.requested.state, response.data.command.response.state, true))
              return
            }
          }
          
          // if not just a config mismatch, handle the error
          this.error = true
          
          if (response.data.command.response.error_code) {
            this.errorText = response.data.command.response.message
          } else {
            this.errorText = 'Device shadow command failed...'
          }
          
          if (response.data.command.requested && response.data.command.requested.state) {
            this.requested = response.data.command.requested
            this.requested.timestamp = response.data.command.finished_timestamp
          }
          
          if (response.data.command.response && response.data.command.response.state) {
            this.reported = response.data.command.response
            this.reported.timestamp = response.data.command.finished_timestamp
          } else if (response.data.command.response && response.data.command.response.current_version > this.reported.current_version) {
            // TODO something better here
            console.log('VideonDeviceShadow ' + this.device_guid + ' ' + this.shadow_name + ' v' + this.version() + ' checkShadowCommand version updated but no state returned', response.data.command.response)
            this.reported.current_version = response.data.command.response.current_version
          }
          
          if (response.data.command.requested && response.data.command.requested.state && response.data.command.response.state) {
            console.log('VideonDeviceShadow ' + this.device_guid + ' ' + this.shadow_name + ' v' + this.version() + ' checkShadowCommand requested/reported diff', Vue.helpers.shadowConfigDiff(response.data.command.requested.state, response.data.command.response.state))
          }
          
          // reset updatePending...
          this.updatePending = false
          
          // update device properties...
          Vue.devices.getDevice(this.device_guid).refreshProperties()
        } 
        
      } else {
        // poll until we get updated data, or we timeout...
        if (this.commandResponseCheckAttempt < this.commandResponseCheckMaxAttempts) {
          this.commandResponseTimer = setTimeout(() => {
            this.commandResponseCheckAttempt++
            this.checkShadowCommand()
          }, this.commandResponseCheckInterval)
        } else {
          this.error = true
          
          if (Vue.devices.getDevice(this.device_guid).isOnline()) {
            this.errorText = 'The device did not acknowledge the request within ' + ((this.commandResponseCheckAttempt * this.commandResponseCheckInterval) / 1000) + ' seconds...'
          } else {
            this.errorText = 'The updated configuration will be applied once the device comes online again...'
          }
          
          console.log('VideonDeviceShadow ' + this.device_guid + ' ' + this.shadow_name + ' v' + this.version() + ' checkShadowCommand error', this.errorText)
          
          this.commandGUID = ''
          this.requesting = false
          this.updatePending = false
          
          this.fetchShadow()
        }
      }
    }).catch((error) => {
      this.error = true
      this.errorText = Vue.helpers.parseError(error)
      this.commandGUID = ''
      this.requesting = false
      
      this.fetchShadow()
    })
  }
  
  saveShadow(state) {
    if (!state) {
      console.log('VideonDeviceShadow ' + this.device_guid + ' saveShadow missing data', this.reported, state)
      return
    }
    
    this.updatePending = true
    this.requestPending = false
    
    this.error = false
    this.errorText = ''
    this.shadowDiff = false
    
    // validSessionCheck
    store.dispatch('user/validSessionCheck').then(() => {
      // update local config state
      if (Array.isArray(state)) {
        for (var index in state) {
          if (this.reported && this.reported.state[index]) {
            this.reported.state[index].config = state[index].config
          }
        }
      } else {
        this.reported.state.config = state.config
      }
      
      // send command
      this.ready = false
      
      var shadowCommand = {
        'command_type': 'set',
        'commands': [
          {
            'shadow_name': this.shadow_name,
            'target_version': (this.reported) ? this.reported.current_version : 0,
            'state': state
          }
        ]
      }
      
      console.log('VideonDeviceShadow ' + this.device_guid + ' ' + this.shadow_name + ' v' + this.version() + ' saveShadow command', shadowCommand)
      
      Vue.axios.post('/devices/' + this.device_guid + '/shadows/commands', shadowCommand)
      .then((response) => {
        console.log('VideonDeviceShadow ' + this.device_guid + ' ' + this.shadow_name + ' v' + this.version() + ' saveShadow response', response)
        
        if (response.data.commands[0] && response.data.commands[0].command_guid) {
          this.error = false
          this.errorText = ''
          
          this.commandGUID = response.data.commands[0].command_guid
          
          this.commandResponseCheckAttempt = 0
          this.commandResponseTimer = setTimeout(() => {
            this.checkShadowCommand()
          }, this.commandResponseCheckInterval)
        }
      }).catch((error) => {
        this.error = true
        this.errorText = Vue.helpers.parseError(error)
        this.ready = true
        this.updatePending = false
      })
    }).catch ((error) => {
      this.error = true
      this.errorText = Vue.helpers.parseError(error)
      this.ready = true
      this.updatePending = false
    })
  }
}

export default new class DeviceShadows {
  constructor() {
    this.shadows = []
  }
  
  reset() {
    console.log('DeviceShadows reset')
    
    // reset array
    this.shadows.splice(0)
  }
  
  getDeviceShadow(device_guid, shadow_name, shadow_attributes = false) {
    if (!device_guid || !shadow_name) {
      return false
    }
    
    var shadow = this.shadows.find(x => x.device_guid === device_guid && x.shadow_name === shadow_name)
    if (shadow) {
      return shadow
    }
    
    shadow = Vue.observable(new VideonDeviceShadow(device_guid, shadow_name, shadow_attributes))
    this.shadows.push(shadow)
    
    return shadow
  }
}