<template>
  <div>
    <v-dialog
      v-model="showDebug"
      max-width="90%"
      v-if="shadowDebug"
    >
      <videon-card heading="Shadow Event Debug" showClose="true" @close="showDebug = false">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <h4>Unfiltered Changes Diff</h4>
              <pre style="inline-size: 640px; white-space: pre-wrap;">{{ shadowDiffAll }}</pre>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <h4>From</h4>
              <pre style="inline-size: 640px; white-space: pre-wrap;">{{ this.eventData.from_shadow }}</pre>
            </v-col>
            <v-col cols="6">
              <h4>To</h4>
              <pre style="inline-size: 640px; white-space: pre-wrap;">{{ this.eventData.to_shadow }}</pre>
            </v-col>
          </v-row>
        </v-card-text>
      </videon-card>
    </v-dialog>
    <v-alert outlined dense rounded class="shadow-chip ma-1 font-weight-medium" @click="showDebug = true" v-if="!shadowDiff || shadowDiff.length == 0">
      {{ shadowName }} shadow updated with no visible changes
    </v-alert>
    <span v-else>
      <v-alert outlined dense rounded class="shadow-chip ma-1"
        v-for="(document, id) in shadowDiff" v-bind:key="id"
        :style="(shadowDebug) ? 'cursor: pointer;' : ''"
        @click="showDebug = true"
      >
        <span v-if="document.name" class="font-weight-bold">{{ document.name }}:&nbsp;</span>
        <span v-else-if="document.label" class="font-weight-bold">{{ document.label }}:&nbsp;</span>
        <span v-else-if="document.shadow == 'system'" class="font-weight-bold">System:&nbsp;</span>
        
        <span v-for="(change, index) in document.changes" v-bind:key="index">
          <span class="text-capitalize font-weight-medium">{{ nameLabel(change.key) }}</span>:
          <span v-if="!change.array">{{ parseValue(change.from) }} <v-icon x-small>mdi-arrow-right</v-icon>{{ parseValue(change.to) }}&nbsp;</span>
          <span v-else-if="change.array.length == 0" class="font-weight-regular text--disabled">[ No Visible Changes ]&nbsp;</span>
          <span v-else>
            [&nbsp;
              <recursive-diff-content :diffContent="change.array" />
            ]&nbsp;
          </span>
        </span>
        
      </v-alert>
    </span>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  
  export default {
    name: 'ShadowChangeDiff',
    props: {
      eventData: {
        type: Object,
        default: () => {},
      },
      device: {
        type: Object,
        default: () => {},
      }
    },
    data() {
      return {
        showDebug: false,
      }
    },
    computed: {
      ...mapGetters('userPreferences', ['shadowDebug']),
      
      shadowName() {
        return this.eventData.to_shadow.shadow_name
      },
      shadowDiff() {
        return this.$helpers.shadowStateDiff(this.eventData.from_shadow.state_reported, this.eventData.to_shadow.state_reported)
      },
      shadowDiffAll() {
        return this.$helpers.shadowStateDiff(this.eventData.from_shadow.state_reported, this.eventData.to_shadow.state_reported, true)
      },
      encoders() {
        return this.device.encodersState()
      }
    },
    methods: {
      nameLabel(stateName) {
        return stateName.replace(/_/g, ' ')
      },
      parseValue(value) {
        if (value === undefined) {
          return 'undefined'
        } else if (value === '') {
          return '\'\''
        }
        return this.$helpers.getLabel(value)
      },
      isTimestamp(stateName) {
        if (stateName == 'timestamp') {
          return true
        }
        return false
      }
    }
  }
</script>
<style lang="scss" scoped>
  .shadow-chip {
     font-size: 12px;
     line-height: 20px;
     padding: 3px 12px;
     min-height: 24px;
     align-items: center;
     display: inline-flex;
  }
  .theme--light.shadow-chip {
      border-color: rgba(0, 0, 0, 0.12) !important;
      color: rgba(0, 0, 0, 0.87) !important;
  }
  .theme--dark.shadow-chip {
    border-color: rgba(255, 255, 255, 0.12) !important;
    color: #FFFFFF !important;
  }
</style>