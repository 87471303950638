<template>
  <div class="d-inline-block text-center" v-if="capabilities">
    <v-dialog
      v-if="device"
      v-model="showCapabilities"
      max-width="480px"
    >
      <videon-card :heading="capabilities.displayName()" showClose="true" @close="showCapabilities = false">
        <div v-if="!capabilities || !capabilities.hardware" class="px-5 pb-2">
          <v-alert outlined type="error">
            Device capabilities unknown
          </v-alert>
        </div>
        <v-card-text v-if="capabilities && capabilities.hardware">
          <hardware-capabilities :capabilities="capabilities" :icon="device.icon()" />
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <span @click="showCapabilities = true" style="cursor: pointer;" v-if="capabilities"><v-icon v-if="device.icon()">{{ device.icon() }}</v-icon> {{ capabilities.displayName() }}</span>
  </div>
</template>
<script>
  import HardwareCapabilities from '../capabilities/HardwareCapabilities.vue'
  
  export default {
    name: 'ProductLabel',
    components: { 
      HardwareCapabilities
    },
    props: ['device'],
    computed: {
      capabilities() {
        return this.device.capabilities()
      }
    },
    data() {
      return {
        showCapabilities: false,
      }
    }
  }
</script>