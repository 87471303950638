import Vue from 'vue'

import moment from 'moment'

export default class DeviceMetrics {
  constructor() {
    this.metrics = []
    
    this.devices = []
    this.metrics = []
    this.unit = ''
    this.label = ''
    
    this.period = 60 * 60 // 60min default
    this.statistic = 'Average'
    
    this.start_time = ''
    this.end_time = ''
    this.scan_by = 'TimestampAscending'
    
    // TODO actual pagination
    this.pagination_size = 50
    this.paginationTokens = []
  }
  
  chartData() {
    return this.metrics
  }
  
  fetchMetrics() {
    return new Promise((resolve, reject) => {
      if (!this.devices || (Array.isArray(this.devices) && this.devices.length == 0)) {
        reject(new Error('No devices specified for query'))
        return
      } else if (!Array.isArray(this.devices)) {
        this.devices = [ this.devices ]
      }
      
      var devicesArray = this.devices.map((device) => { return device.device_guid })
      for (var device_guid of devicesArray) {
        if (!device_guid) {
          reject(new Error('Device has no device_guid'))
          return
        }
      }
      
      var metricsArray = this.metrics.map((metric) => {
        var metricParts = metric.split('|')
        return {
          'namespace': metricParts[0],
          'metric_name': metricParts[1],
          'period': this.period,
          'statistic': this.statistic
        }
      })
      
      Vue.axios.post('/devices/metrics', { 
          'device_guids': devicesArray,
          'metrics': metricsArray,
          'start_time': this.start_time,
          'end_time': this.end_time,
          'scan_by': this.scan_by,
          'pagination_size': this.pagination_size,
        }).then((result) => {
          console.log('DeviceMetrics ' + this.devices.map((device) => { return device.device_guid }).join(', ') + ' fetchMetrics', result)
          
          if (result.data.metrics && result.data.metrics.length > 0) {
            this.metrics = result.data.metrics.map((metric) => {
              var [ device_guid, metric_name ] = metric.label.split(' ')
              
              // This lookup could potentially be slow
              var device_index = this.devices.findIndex((device) => device.device_guid == device_guid)
              var metric_index = metricsArray.findIndex((metric) => metric.metric_name == metric_name)

              return {
                'device_index': device_index,
                'metric_index': metric_index,
                
                'label': this.metricLabel(metric.label),
                'data': this.insertNulls(metric.values.map((value, index) => ({
                  'x': metric.timestamps[index],
                  'y': (value) ? value.toFixed(2) : null
                })))
              }
            })
          }
          
          resolve(this.metrics)
        }).catch((error) => {
          console.log('DeviceMetrics ' + this.devices.map((device) => { return device.device_guid }).join(', ') + ' fetchMetrics', error)
          reject(error)
        })
    })
  }
  
  insertNulls(data) {
    var newData = []
    
    // insert element 0
    newData.push(data[0])
    
    for (var index in data) {
      if (index > 0) {
        var dateB = moment(data[index].x)
        var dateA = moment(data[index - 1].x)
        
        var elapsed = dateB.diff(dateA, 'seconds')
        
        if (elapsed > this.period) {
          // insert N items
          var intervals = elapsed / this.period
          
          for (var x = 1; x < intervals; x++) {
            newData.push({'x': dateA.add(this.period, 'second').toISOString(), 'y': null})
          }
          
          newData.push(data[index])
        } else {
          newData.push(data[index])
        }
      }
    }
    
    return newData
  }
  
  metricLabel(label) {
    // likely contains a guid & possibly metric
    if (label.length >= 36) {
      var metricLabel = ''
      
      var guid = label.substring(0, 36)
      
      var device = this.devices.find(x => x.device_guid === guid)
      if (device) {
        metricLabel = device.deviceName()
      } else {
        metricLabel = guid
      }
      
      if (label.length > 36) {
        metricLabel += ' - ' + Vue.helpers.getLabel(label.substring(37))
      }
      
      return metricLabel
    }
    
    // just lookup a label
    return Vue.helpers.getLabel(label)
  }
  
  last(metricIndex = 0) {
    if (this.metrics.length > 0 && this.metrics[metricIndex].data.length > 0 && this.metrics[metricIndex].data[this.metrics[metricIndex].data.length - 1]) {
      return this.metrics[metricIndex].data[this.metrics[metricIndex].data.length - 1].y
    }
    
    return false
  }
  
  lastMax() {
    var lastYs = []
    for (var i = 0; i<this.metrics.length; i++) {
      if (this.metrics[i].data.length > 1 && this.metrics[i].data[this.metrics[i].data.length - 1]) {
        lastYs.push(this.metrics[i].data[this.metrics[i].data.length - 1].y)
      }
    }
    if (lastYs.length > 0) {
      return Math.max(...lastYs)
    }
    return false
  }
  
  average() {
    return this.sum() / this.metrics.reduce((sum, metric) => (
      sum + metric.data.length
    ), 0)
  }

  max() {
    const max = Math.max(...this.metrics.reduce((merged, metric) => (
      merged.concat(metric.data.map(d => d.y))
    ), []));
    return max === -Infinity ? 0 : max
  }

  sum() {
    return this.metrics.reduce((sum, metric) => (
      sum + metric.data.reduce((s, d) => s + d.y, 0)
    ), 0)
  }
  

}