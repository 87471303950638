import helpers from '../helpers.js'

export default {
  // eslint-disable-next-line no-unused-vars
  shadowIgnoreKeys: [
    'raw',
    'available_drives',
    'seconds_in_status',
    'current_bitrate',
    'passphrase',
    'password',
    'sftp_key',
    'service_data',
    'encryption_key',
    'last_state_update'
  ],
  
  multicastStatus: (shadow) => {
    //console.log('multicastStatus', shadow)
    var outputStatus = {'configured': false, 'enabled': shadow.config.enable, 'streaming': shadow.config.enable, 'status_code': '', 'status_message': '', 'destination': ''}
    
    if (
      shadow.config.destination_ip
      && shadow.config.destination_port
      && shadow.config.sources.video.length > 0
    ) {
      outputStatus.configured = true
      outputStatus.destination = ((shadow.config.protocol == 'RTP') ? 'rtp://' : 'udp://') + shadow.config.destination_ip + ':' + shadow.config.destination_port
    }
    
    if (shadow.status_code) {
      outputStatus.streaming = false
      
      outputStatus.status_code = shadow.status_code
      outputStatus.status_message = shadow.status_message
      
      if (shadow.status_code == 'STREAM_RUNNING' || shadow.status_code == 'RUNNING') {
        outputStatus.streaming = true
      }
    }
    
    return outputStatus
  },
  
  unicastStatus: (shadow) => {
    //console.log('unicastStatus', shadow)
    var outputStatus = {'configured': false, 'enabled': shadow.config.enable, 'streaming': shadow.config.enable, 'status_code': '', 'status_message': '', 'destination': ''}
    
    if (
      shadow.config.destination_ip
      && shadow.config.destination_port
      && shadow.config.sources.video.length > 0
    ) {
      outputStatus.configured = true
      outputStatus.destination = ((shadow.config.protocol == 'RTP') ? 'rtp://' : 'udp://') + shadow.config.destination_ip + ':' + shadow.config.destination_port
    }
    
    if (shadow.status_code) {
      outputStatus.streaming = false
      
      outputStatus.status_code = shadow.status_code
      outputStatus.status_message = shadow.status_message
      
      if (shadow.status_code == 'STREAM_RUNNING' || shadow.status_code == 'RUNNING') {
        outputStatus.streaming = true
      }
    }

    return outputStatus
  },
  
  rtmpStatus: (shadow) => {
    //console.log('rtmpStatus', shadow.status)
    var outputStatus = {'configured': false, 'enabled': shadow.config.enable, 'streaming': shadow.config.enable, 'status_code': '', 'status_message': '', 'destination': ''}
    
    if (
      shadow.config.service_data &&
      shadow.config.service_data.length > 30 // TODO do this better at some point
      && shadow.config.sources.video.length > 0
    ) {
      outputStatus.configured = true
      
      var serviceData = JSON.parse(shadow.config.service_data)
      if (serviceData.url) {
        outputStatus.destination = serviceData.url
      } else {
        outputStatus.destination = helpers.getLabel(shadow.config.service_name)
      }
    }
    
    if (shadow.status_code) {
      outputStatus.streaming = false
      
      outputStatus.status_code = shadow.status_code
      outputStatus.status_message = shadow.status_message
      
      if (shadow.status_code == 'STREAM_RUNNING' || shadow.status_code == 'RUNNING') {
        outputStatus.streaming = true
      }
    }
    
    return outputStatus
  },
  
  fileStatus: (shadow) => {
    //console.log('fileStatus', shadow)
    var outputStatus = {'configured': false, 'enabled': shadow.config.enable, 'streaming': shadow.config.enable, 'status_code': '', 'status_message': '', 'destination': ''}
    
    if (
      shadow.config.device_selection_policy_data
      && shadow.config.sources.video.length > 0
    ) {
      outputStatus.configured = true
    }
    
    if (shadow.status_code) {
      outputStatus.streaming = false
      
      outputStatus.status_code = shadow.status_code
      outputStatus.status_message = shadow.status_message
      
      if (shadow.status_code == 'STREAM_RUNNING' || shadow.status_code == 'RUNNING') {
        outputStatus.streaming = true
      } 
      
      if (shadow.config && shadow.config.ftp_config && shadow.config.ftp_config.enable == true && shadow.ftp_state && shadow.ftp_state.current_state) {
        outputStatus.status_message = outputStatus.status_message += ' [FTP State: ' + helpers.getLabel(shadow.ftp_state.current_state) + ']'
      }
    }
    
    return outputStatus
  },
  
  rtspStatus: (shadow) => {
    //console.log('rtspStatus', shadow)
    var outputStatus = {'configured': false, 'enabled': shadow.config.enable, 'streaming': shadow.config.enable, 'status_code': '', 'status_message': '', 'destination': ''}
    
    if (
      shadow.config.stream_name
      && shadow.config.server_port
      && shadow.config.sources.video.length > 0
    ) {
      outputStatus.configured = true
      outputStatus.destination = 'rtsp://:' + shadow.config.server_port + ' (' + shadow.config.stream_name + ')'
    }
    
    if (shadow.status_code) {
      outputStatus.streaming = false
      
      outputStatus.status_code = shadow.status_code
      outputStatus.status_message = shadow.status_message
      
      if (shadow.status_code == 'STREAM_RUNNING' || shadow.status_code == 'RUNNING') {
        outputStatus.streaming = true
      }
    }
    
    return outputStatus
  },
  
  http_pullStatus: (shadow) => {
    //console.log('http_pullStatus', shadow)
    var outputStatus = {'configured': false, 'enabled': shadow.config.enable, 'streaming': shadow.config.enable, 'status_code': '', 'status_message': '', 'destination': ''}
    
    if (
      shadow.config.sources.video.length > 0
    ) {
      outputStatus.configured = true
    }
    
    if (shadow.status_code) {
      outputStatus.streaming = false
      
      outputStatus.status_code = shadow.status_code
      outputStatus.status_message = shadow.status_message
      
      if (shadow.status_code == 'STREAM_RUNNING' || shadow.status_code == 'RUNNING') {
        outputStatus.streaming = true
      }
    }
    
    return outputStatus
  },
  
  http_pushStatus: (shadow) => {
    //console.log('http_pushStatus', shadow)
    var outputStatus = {'configured': false, 'enabled': shadow.config.enable, 'streaming': shadow.config.enable, 'status_code': '', 'status_message': '', 'destination': ''}
    
    if (
      shadow.config.protocols
      && shadow.config.protocols.length > 0
      && shadow.config.service_data
      && shadow.config.service_data.length > 30 // TODO do this better at some point
      && shadow.config.sources.video.length > 0
    ) {
      outputStatus.configured = true
      
      var serviceData = JSON.parse(shadow.config.service_data)
      if (serviceData.push_url) {
        outputStatus.destination = serviceData.push_url
      }
    }
    
    if (shadow.status_code) {
      outputStatus.streaming = false
      
      outputStatus.status_code = shadow.status_code
      outputStatus.status_message = shadow.status_message
      
      if (shadow.status_code == 'STREAM_RUNNING' || shadow.status_code == 'RUNNING') {
        outputStatus.streaming = true
      }
    }
    
    return outputStatus
  },
  
  srtStatus: (shadow) => {
    //console.log('srtStatus', shadow)
    var outputStatus = {'configured': false, 'enabled': shadow.config.enable, 'streaming': shadow.config.enable, 'status_code': '', 'status_message': '', 'destination': ''}
    
    if (
      shadow.config.destination_port
      && shadow.config.sources.video.length > 0
    ) {
      outputStatus.configured = true
      
      if (shadow.config.call_mode == 'LISTENER') {
        outputStatus.destination = 'srt://:' + shadow.config.destination_port
      } else {
        outputStatus.destination = 'srt://' + shadow.config.destination_ip + ':' + shadow.config.destination_port
      }
      
    }
    
    if (shadow.status_code) {
      outputStatus.streaming = false
      
      outputStatus.status_code = shadow.status_code
      outputStatus.status_message = shadow.status_message
      
      if (shadow.status_code == 'STREAM_RUNNING' || shadow.status_code == 'RUNNING') {
        outputStatus.streaming = true
      }
    }
    
    return outputStatus
  },
  
  zixiStatus: (shadow) => {
    //console.log('zixiStatus', shadow)
    var outputStatus = {'configured': false, 'enabled': shadow.config.enable, 'streaming': shadow.config.enable, 'status_code': '', 'status_message': '', 'destination': ''}
    
    if (
      shadow.config.destination_ip
      && shadow.config.destination_port
      && shadow.config.sources.video.length > 0
    ) {
      outputStatus.configured = true
      outputStatus.destination = 'zixi://' + shadow.config.destination_ip + ':' + shadow.config.destination_port
    }
    
    if (shadow.status_code) {
      outputStatus.streaming = false
      
      outputStatus.status_code = shadow.status_code
      outputStatus.status_message = shadow.status_message
      
      if (shadow.status_code == 'STREAM_RUNNING' || shadow.status_code == 'RUNNING') {
        outputStatus.streaming = true
      }
    }
    
    return outputStatus
  },
  
  whipStatus: (shadow) => {
    //console.log('whipStatus', shadow)
    var outputStatus = {'configured': false, 'enabled': shadow.config.enable, 'streaming': shadow.config.enable, 'status_code': '', 'status_message': '', 'destination': ''}
    
    if (
      shadow.config.service_name
      && shadow.config.service_data
      && shadow.config.service_data.length > 14 // TODO do this better at some point
      && shadow.config.sources.video.length > 0
    ) {
      outputStatus.configured = true
      
      var serviceData = JSON.parse(shadow.config.service_data)
      if (serviceData.url) {
        outputStatus.destination = serviceData.url
      }
    }
    
    if (shadow.status_code) {
      outputStatus.streaming = false
      
      outputStatus.status_code = shadow.status_code
      outputStatus.status_message = shadow.status_message
      
      if (shadow.status_code == 'STREAM_RUNNING' || shadow.status_code == 'RUNNING') {
        outputStatus.streaming = true
      }
    }
    
    return outputStatus
  },
  
  thumbnailStatus: (shadow) => {
    //console.log('thumbnailStatus', shadow)
    var outputStatus = {'configured': false, 'enabled': shadow.config.enable, 'streaming': shadow.config.enable, 'status_code': '', 'status_message': '', 'destination': ''}
    
    if (
      shadow.config.sources.video.length > 0
    ) {
      outputStatus.configured = true
    }
    
    if (shadow.status_code) {
      outputStatus.streaming = false
      
      outputStatus.status_code = shadow.status_code
      outputStatus.status_message = shadow.status_message
      
      if (shadow.status_code == 'STREAM_RUNNING' || shadow.status_code == 'RUNNING') {
        outputStatus.streaming = true
      }
    }
    
    return outputStatus
  },
  unknownStatus: (shadow) => {
    //console.log('unknownStatus', shadow)
    var outputStatus = {'configured': false, 'enabled': shadow.config.enable, 'streaming': shadow.config.enable, 'status_code': '', 'status_message': '', 'destination': ''}
    
    if (
      shadow.config.sources.video.length > 0
    ) {
      outputStatus.configured = true
    }
    
    if (shadow.status_code) {
      outputStatus.streaming = false
      
      outputStatus.status_code = shadow.status_code
      outputStatus.status_message = shadow.status_message
      
      if (shadow.status_code == 'STREAM_RUNNING' || shadow.status_code == 'RUNNING') {
        outputStatus.streaming = true
      }
    }
    
    return outputStatus
  },
  
  
  allKeys(from, to) {
    var allKeys = []
    
    var fromKeys = Object.keys(from || {})
    var toKeys = Object.keys(to || {})
    
    allKeys = allKeys.concat(fromKeys, toKeys)
    
    return allKeys.filter((item, index) => allKeys.indexOf(item) === index)
  },
  
  shadowStateDiff(from, to, allKeys) {
    var shadowKeys = this.allKeys(from, to)
    var shadowKey = shadowKeys[0]
    
    var fromShadow = (from && from[shadowKey] !== undefined) ? from[shadowKey] : {}
    var toShadow = (to && to[shadowKey] !== undefined) ? to[shadowKey] : {}
    
    // handle system shadow
    if (!Array.isArray(fromShadow) || !Array.isArray(toShadow)) {
      changes = helpers.recursiveDiff(fromShadow, toShadow, allKeys)
      if (changes.length > 0) {
        return [{'shadow': shadowKey, 'id': -1, 'changes': changes}]
      }
      return false
    }
    
    var componentIDs = []
    
    var fromIDs = fromShadow.map((item) => {
      if (item.alert_guid) {
        return item.alert_guid
      }
      return item.id
    })
    
    var toIDs = toShadow.map((item) => {
      if (item.alert_guid) {
        return item.alert_guid
      }
      return item.id
    })
    
    componentIDs = componentIDs.concat(fromIDs, toIDs)
    
    var uniqueComponentIDs = componentIDs.filter((item, index) => componentIDs.indexOf(item) === index) 
    
    var changes
    var shadowDiffs = []
    
    for (var componentID of uniqueComponentIDs) {
      var fromComponent = fromShadow.find(x => (x.id === componentID || x.alert_guid === componentID)) || {}
      var toComponent = toShadow.find(x => (x.id === componentID || x.alert_guid === componentID)) || {}
      
      changes = helpers.recursiveDiff(fromComponent, toComponent, allKeys)
      if (changes.length > 0) {
        shadowDiffs.push({'shadow': shadowKey, 'id': componentID, 'type': toComponent.type || fromComponent.type, 'label': toComponent.label || fromComponent.label, 'name': (toComponent.config && toComponent.config.name || fromComponent.config && fromComponent.config.name || ''), 'changes': changes})
      }
    }
    
    return shadowDiffs
  },
  
  shadowConfigDiff(from, to, allKeys) {
    // system shadow
    if (!Array.isArray(to)) {
      if (to.config && from.config) {
        return helpers.recursiveDiff(from.config, to.config, allKeys)
      }
      console.log('shadowConfigDiff passed invalid data')
      return []
    }
    
    var changes
    var shadowDiffs = []
    
    for (var index in to) {
      var toConfig = (to && to[index].config) ? to[index].config : {}
      var fromComponent = from.find(x => (x.id === to[index].id || x.alert_guid === to[index].alert_guid))
      var fromConfig = (fromComponent && fromComponent.config) ? fromComponent.config : {}
      
      if (toConfig && fromConfig) {
        changes = helpers.recursiveDiff(fromConfig, toConfig, allKeys)
        if (changes.length > 0) {
          shadowDiffs.push({'id': to[index].id, 'name': (to[index].config.name) ? to[index].config.name : '' ,'changes': changes})
        }
      }
      
    }
    return shadowDiffs
  },
}