<template>
  <div>
    <div v-if="!isLicensed" class="ma-2">
      <v-row align="center" style="min-height: 200px;">
        <v-col cols="2" class="text-center"><v-icon size="72" color="red">warning</v-icon></v-col>
        <v-col cols="9">
          <h2 class="my-5">Not Licensed</h2>
          <p>
            Container functionality is not licensed for this device.
          </p>
        </v-col>
      </v-row>
    </div>
    
    
    <!-- TODO: Do we want to consolidate errors into one thing?-->
    <v-alert
      dense 
      outlined 
      type="error" 
      class="ma-3"
      v-if="error || command_status == 'failed' || command_status == 'error' || command_status == 'rejected'"
    >
        {{ errorText || command_message }}
    </v-alert>
    
    <div v-if="!isOnline" class="ma-2">
      <v-row align="center" style="min-height: 200px;">
        <v-col cols="2" class="text-center"><v-icon size="72" color="red">cloud_off</v-icon></v-col>
        <v-col cols="9">
          <h2 class="my-5">Device Offline</h2>
          <p>
            Container management is not available while the device is offline.
          </p>
        </v-col>
      </v-row>
    </div>
    
    <div v-if="isLicensed">
      <div class="d-flex align-end flex-column mb-3 mr-0">
        <v-btn-toggle
          v-model="advancedView"
          mandatory
          rounded
        >
          <v-btn x-small :value="false">
            Standard
          </v-btn>
          <v-btn x-small :value="true">
            Advanced
          </v-btn>
        </v-btn-toggle>
      </div>
      
      <div v-if="!advancedView">
        
        <div v-if="!isStandardDockerSupported" class="ma-2">
          <v-row align="center" style="min-height: 200px;">
            <v-col cols="2" class="text-center"><v-icon size="72" color="red">warning</v-icon></v-col>
            <v-col cols="9">
              <h2 class="my-5">Unsupported Version</h2>
              <p>
                The version of your cloud agent does not support Standard container management. Please use the advanced mode or update to the latest version.
              </p>
            </v-col>
          </v-row>
        </div>
        
        <containers-list
          v-else
          :device_guid="device_guid"
          :loading="containersLoading || loading"
          :isOnline="isOnline"
          :isProcessing="isProcessing"
          :actionedContainer="actionedContainer"
          :containers="containers"
          :licenses="containerLicenses"
          :platform="device.product_name"
          :canEdit="canEdit"
          @install="installContainer"
          @uninstall="uninstallContainer"
          @refresh="refreshContainers"
          @containerAction="containerAction"
        ></containers-list>
      </div>
      <div v-else>
        <div v-if="!isAdvancedDockerSupported" class="ma-2">
          <v-row align="center" style="min-height: 200px;">
            <v-col cols="2" class="text-center"><v-icon size="72" color="red">warning</v-icon></v-col>
            <v-col cols="9">
              <h2 class="my-5">Not Supported</h2>
              <p>
                The version of your cloud agent does not support container management at all. Please update to the latest cloud agent.
              </p>
            </v-col>
          </v-row>
        </div>
        
        <div v-else>
          <docker-containers-list
            :device_guid="device_guid"
            :loading="(containersLoading && !noContainers) || loading"
            :hasPrivileged="hasPrivileged"
            :isAdmin="isAdmin"
            :isOnline="isOnline"
            :isProcessing="isProcessing"
            :actionedContainer="actionedContainer"
            :containers="containers"
            :images="images"
            :canEdit="canEdit"
            @create="createContainer"
            @delete="deleteContainer"
            @refresh="refreshContainers"
            @containerAction="containerAction"
          ></docker-containers-list>
      
          <docker-images-list
            :isAdmin="isAdmin"
            :isOnline="isOnline"
            :isProcessing="isProcessing"
            :loading="imagesLoading || loading"
            :images="images"
            :canEdit="canEdit"
            @pull="pullImage"
            @delete="deleteImage"
          ></docker-images-list>
        </div>
        
      </div>
    </div>
    
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'

  import ContainersList from '../components/containers/ContainersList.vue'
  import DockerContainersList from '../components/containers/DockerContainersList.vue'
  import DockerImagesList from '../components/containers/DockerImagesList.vue'
  
  export default {
    name: 'DeviceContainers',

    components: {
      ContainersList,
      DockerImagesList,
      DockerContainersList
    },
    
    props: ['device_guid', 'device'],
        
    data() {
      return {
        minimumStandardAgentVersion: this.$helpers.parseVersion('cloud', 'V1.18.1'),
        minimumAdvancedAgentVersion: this.$helpers.parseVersion('cloud', 'V1.0.0b34'),
        
        commandGUID: false,
        commandResponseTimer: false,
        commandResponseCheckAttempt: false,
        commandResponseCheckInterval: 5000,
        commandResponseCheckMaxAttempts: 50,
        command_status: false,
        command_message: false,
        
        loading: false,
        advancedView: false,
        
        error: false,
        errorText: false,
        
        containersLoading: false,
        noContainers: false,
        imagesLoading: false,
        
        actionedContainer: false,
        
        containers: [],
        images: [],
        
        refreshTimer: false,
        refreshInterval: 15000
      }
    },
    watch: {
      
    },
    computed: {
      ...mapGetters('user', ['user', 'guid', 'isAdmin']),
      
      canEdit() {
        if (this.device) {
          return this.device.canEdit()
        }
        
        return false
      },
      
      isLicensed() {
        return this.device.hasFeature('services.docker.enabled')
      },
      
      containerLicenses() {
        return this.device.featureValue('services.docker.containers', [])
      },
      
      isStandardDockerSupported() {
        if (this.device.cloud_version.dev || this.$helpers.versionCheck(this.minimumStandardAgentVersion, this.device.cloud_version)) {
          return true
        }
        return false
      },
      
      isAdvancedDockerSupported() {
        if (this.device.product_name == 'edgecaster_max') {
          return true
        } else if (this.device.cloud_version.dev || this.$helpers.versionCheck(this.minimumAdvancedAgentVersion, this.device.cloud_version)) {
          return true
        }
        return false
      },
      
      isProcessing() {
        if (this.commandGUID) {
          return true
        }
        return false
      },
      
      isOnline() {
        return this.device.isOnline()
      },
      
      hasPrivileged() {
        return this.device.hasFeature('services.docker.privileged')
      }
    },
    
    mounted() {
      setTimeout(() => {
        this.fetchDockerImages(true)
        this.fetchDockerContainers(true)
      
        // default to advanced view if standard view is not supported
        if (!this.isStandardDockerSupported) {
          this.advancedView = true
        }
      }, 1500)
    },
    
    beforeDestroy() {
      if (this.refreshTimer) {
        clearTimeout(this.refreshTimer)
        this.refreshTimer = false
      }
      if (this.commandResponseTimer) {
        clearTimeout(this.commandResponseTimer)
        this.commandResponseTimer = false
      }
    },
    
    methods: {
      refreshContainers() {
        clearTimeout(this.refreshTimer)
        this.refreshTimer = false

        this.fetchDockerContainers(true)
        this.fetchDockerImages(true)
      },
      
      fetchDockerContainers(force = false) {
        if (this.isLicensed && (force || document.hasFocus())) {
          this.error = false
          this.errorText = false
          this.containersLoading = true
          
          this.axios.get('devices/' + this.device_guid + '/docker/containers').then((response) => {
            console.log('DeviceContainers fetchDockerContainers ', response)
          
            this.containers = response.data.containers
            
            if (this.containers.length == 0) {
              this.noContainers = true
            }
            
            this.actionedContainer = false
            this.containersLoading = false
          }).catch((error) => {
            this.error = true
            this.errorText = this.$helpers.parseError(error)
            this.containersLoading = false
          })
        }
        
        if (this.refreshInterval) {
          this.refreshTimer = setTimeout(() => {
            if (this.refreshTimer) {
              this.fetchDockerContainers()
              this.fetchDockerImages()
            }
          }, this.refreshInterval)
        }
      },
      
      fetchDockerImages(force = false) {
        if (this.isLicensed && (force || document.hasFocus())) {
          this.imagesLoading = true
          
          this.axios.get('devices/' + this.device_guid + '/docker/images').then((response) => {
            console.log('DeviceContainers fetchDockerImages ', response)
            
            this.images = response.data.images
            
            this.imagesLoading = false
          }).catch((error) => {
            this.error = true
            this.errorText = this.$helpers.parseError(error)
            this.imagesLoading = false
          })
        }
      },
      
      sendDockerAction(item, action) {
        this.error = false
        this.errorText = false
        
        if (!item.id) {
          return
        }
        
        this.actionedContainer = item
        this.containersLoading = true
        
        clearTimeout(this.refreshTimer)
        this.refreshTimer = false
        
        this.loading = true
        
        this.axios.put('devices/' + this.device_guid + '/docker/containers/' + item.id, {'action': action}).then((response) => {
          console.log('DeviceContainers sendDockerAction ', response)
          
          this.actionedContainer = item
          
          this.command_status = 'accepted'
          this.command_message = 'Command Accepted'
          this.commandGUID = response.data.command_guid
        
          if (this.commandGUID) {
            this.commandResponseCheckAttempt = 0
            this.commandResponseTimer = setTimeout(() => {
              this.commandResponseCheckAttempt++
              this.checkDockerCommand(this.commandGUID)
            }, this.commandResponseCheckInterval)
          }
        }).catch((error) => {
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          
          this.loading = false
          this.actionedContainer = false
        })
      },
      
      // TODO: Finish this
      checkDockerCommand() {
        if (!this.commandGUID) {
          return
        }
        
        this.axios.get('/devices/' + this.device_guid + '/commands/' + this.commandGUID)
        .then((response) => {
          console.log('DeviceContainers ' + this.device_guid + ' checkDockerCommand response', response)
          this.last_api_request_id = response.data.api_request_id
          
          if (response.data.command.finished === true) {
            console.log('DeviceContainers ' + this.device_guid + ' checkDockerCommand finished', response.data.command)
            this.commandGUID = false
            
            if (response.data.command.response && response.data.command.response.result) {
              var result = response.data.command.response.result
              console.log('DeviceContainers ' + this.device_guid + ' checkDockerCommand result', result)
              
              this.command_status = result.state.toLowerCase()
              this.command_message = result.message
              
              console.log('DeviceContainers ' + this.device_guid + ' checkDockerCommand ' + this.command_status)
              
              this.loading = false
              this.actionedContainer = false
              
              setTimeout(() => {
                // update container state
                this.fetchDockerContainers(true)
                this.fetchDockerImages(true)
              }, 5000)
            }
          } else {
            // poll until we get updated data, or we timeout...
            if (this.commandResponseCheckAttempt < this.commandResponseCheckMaxAttempts) {
              this.commandResponseTimer = setTimeout(() => {
                this.commandResponseCheckAttempt++
                this.checkDockerCommand(this.commandGUID)
              }, this.commandResponseCheckInterval)
            } else {
              console.log('DeviceContainers ' + this.device_guid + ' checkDockerCommand did not complete')
              
              this.command_status = 'error'
              this.command_message = 'No command response in ' + ((this.commandResponseCheckAttempt * this.commandResponseCheckInterval) / 1000) + ' seconds...'
              
              this.commandGUID = false
              
              this.fetchDockerContainers(true)
              this.fetchDockerImages(true)
            }
          }
        }).catch((error) => {
          console.log('DeviceContainers ' + this.device_guid + ' checkDockerCommand error', this.$helpers.parseError(error))
        }) 
      },

      createContainer(container) {
        this.error = false
        this.errorText = false
        
        clearTimeout(this.refreshTimer)
        this.refreshTimer = false

        this.containersLoading = true
        this.loading = true
        this.noContainers = false
        
        var postBody = {'name': container.name, 'image_id': container.imageId}

        if (container.restart_policy) {
          postBody.restart_policy = container.restart_policy
        }

        if (container.ports) {
          postBody.ports = container.ports
        }

        if (container.environment) {
          postBody.environment = container.environment
        }

        if (container.volumes) {
          postBody.volumes = container.volumes
        }

        if (container.devices) {
          postBody.devices = container.devices
        }
        
        if (container.network_mode) {
          postBody.network_mode = container.network_mode
        }
        
        if (container.privileged) {
          postBody.privileged = container.privileged
        }
        
        // passes validation, lets hide the dialog...
        console.log('DeviceContainers createContainer', postBody)
        
        this.axios.post('devices/' + this.device_guid + '/docker/containers', postBody).then((response) => {
          console.log('DeviceContainers createContainer response', response)
          
          this.command_status = 'accepted'
          this.command_message = 'Command Accepted'
          this.commandGUID = response.data.command_guid
        
          if (this.commandGUID) {
            this.commandResponseCheckAttempt = 0
            this.commandResponseTimer = setTimeout(() => {
              this.commandResponseCheckAttempt++
              this.checkDockerCommand(this.commandGUID)
            }, this.commandResponseCheckInterval)
          }
        }).catch((error) => {
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          this.loading = false
        })
      },
      
      deleteContainer(selectedContainer) {
        this.loading = true
        this.actionedContainer = selectedContainer
        this.containersLoading = true
        
        console.log('DeviceContainers deleteContainer', selectedContainer)
        
        this.loading = true
        
        clearTimeout(this.refreshTimer)
        this.refreshTimer = false
        
        this.axios.delete('devices/' + this.device_guid + '/docker/containers/' + selectedContainer.id, {'data': {'reason': ''}}).then((response) => {
          console.log('DeviceContainers deleteContainer response', response)
          
          this.command_status = 'accepted'
          this.command_message = 'Command Accepted'
          this.commandGUID = response.data.command_guid
        
          if (this.commandGUID) {
            this.commandResponseCheckAttempt = 0
            this.commandResponseTimer = setTimeout(() => {
              this.commandResponseCheckAttempt++
              this.checkDockerCommand(this.commandGUID)
            }, this.commandResponseCheckInterval)
          }
        }).catch((error) => {
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          this.loading = false
          this.actionedContainer = false
        })
      },
      
      installContainer(containerId) {
        if (!containerId) {
          console.log('DeviceContainers installContainer invalid parameter')
          return
        }
        
        this.loading = true
        this.containersLoading = true
        
        clearTimeout(this.refreshTimer)
        this.refreshTimer = false
        
        this.axios.post('devices/' + this.device_guid + '/containers', {'container_id': containerId}).then((response) => {
          console.log('DeviceContainers installContainer response', response)
          
          this.command_status = 'accepted'
          this.command_message = 'Command Accepted'
          this.commandGUID = response.data.command_guid
          
          if (this.commandGUID) {
            this.commandResponseCheckAttempt = 0
            this.commandResponseTimer = setTimeout(() => {
              this.commandResponseCheckAttempt++
              this.checkDockerCommand(this.commandGUID)
            }, this.commandResponseCheckInterval)
          }
        }).catch((error) => {
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          this.loading = false
          this.containersLoading = false
        })
      },
      
      uninstallContainer(selectedContainer) {
        if (!selectedContainer.container_id) {
          console.log("DeviceContainers uninstallContainer selected container does not include container_id")
          return
        }
        
        this.loading = true
        this.actionedContainer = selectedContainer
        this.containersLoading = true
        
        clearTimeout(this.refreshTimer)
        this.refreshTimer = false
        
        this.axios.delete('devices/' + this.device_guid + '/containers/' + selectedContainer.container_id).then((response) => {
          console.log('DeviceContainers uninstallContainer response', response)
          
          this.command_status = 'accepted'
          this.command_message = 'Command Accepted'
          this.commandGUID = response.data.command_guid
          
          if (this.commandGUID) {
            this.commandResponseCheckAttempt = 0
            this.commandResponseTimer = setTimeout(() => {
              this.commandResponseCheckAttempt++
              this.checkDockerCommand(this.commandGUID)
            }, this.commandResponseCheckInterval);
          }
        }).catch((error) => {
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          this.loading = false
          this.containersLoading = false
          this.actionedContainer = false
        })
      },
      
      pullImage(image) {
        console.log('DeviceContainers pullImage', image)
        
        this.imagesLoading = true
        
        clearTimeout(this.refreshTimer)
        this.refreshTimer = false
        
        var postBody = {'repository': image.repository, 'tag': image.tag}
        
        if (image.username && image.password) {
          postBody.username = image.username
          postBody.password = image.password
        } else if (image.repository.includes('ecr')) {
          postBody.videon_ecr = true
        }
        
        this.axios.post('devices/' + this.device_guid + '/docker/images', postBody).then((response) => {
          console.log('DeviceContainers pullImage response', response)
          
          this.command_status = 'accepted'
          this.command_message = 'Command Accepted'
          this.commandGUID = response.data.command_guid
        
          if (this.commandGUID) {
            this.commandResponseCheckAttempt = 0
            this.commandResponseTimer = setTimeout(() => {
              this.commandResponseCheckAttempt++
              this.checkDockerCommand(this.commandGUID)
            }, this.commandResponseCheckInterval)
          }
        }).catch((error) => {
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          this.loading = false
          this.imagesLoading = false
        })
      },
      
      deleteImage(selectedImage) {
        console.log('DeviceContainers deleteImage', selectedImage)
        
        this.imagesLoading = true
        
        clearTimeout(this.refreshTimer)
        this.refreshTimer = false
        
        if (selectedImage) {
          this.axios.delete('devices/' + this.device_guid + '/docker/images/' + selectedImage.id, {'data': {'reason': ''}}).then((response) => {
            console.log('DeviceContainers deleteImage response', response)
            
            this.command_status = 'accepted'
            this.command_message = 'Command Accepted'
            this.commandGUID = response.data.command_guid
            
            if (this.commandGUID) {
              this.commandResponseCheckAttempt = 0
              this.commandResponseTimer = setTimeout(() => {
                this.commandResponseCheckAttempt++
                this.checkDockerCommand(this.commandGUID)
              }, this.commandResponseCheckInterval)
            }
          }).catch((error) => {
            this.error = true
            this.errorText = this.$helpers.parseError(error)
            this.loading = false
            this.imagesLoading = false
          })
        }
      },
      
      containerAction(container, action) {
        console.log('DeviceContainers containerAction', container)
        
        this.sendDockerAction(container, action)
      }
    }
  }
</script>
