<template>
  <span>
    <span v-for="(change, index) in diffContent" v-bind:key="index">
      <span class="text-capitalize font-weight-medium">{{ nameLabel(change.key) }}</span>:&nbsp;
      <span v-if="$helpers.isTimestamp(change.key)">{{ change.from | moment("YYYY/MM/DD hh:mm:ss a") }} <v-icon x-small>mdi-arrow-right</v-icon>{{ change.to | moment("YYYY/MM/DD hh:mm:ss a") }}&nbsp;</span>
      <span v-else-if="!change.array">{{ parseValue(change.from) }} <v-icon x-small>mdi-arrow-right</v-icon>{{ parseValue(change.to) }}&nbsp;</span>
      <span v-else-if="change.array.length == 0" class="font-weight-regular text--disabled">[ No Visible Changes ]&nbsp;</span>
      <span v-else>
        <span v-for="(arrayChange, index) in change.array" v-bind:key="index">
          <span class="text-capitalize font-weight-medium">{{arrayChange.key}}</span>:&nbsp;
          <span v-if="!arrayChange.array">{{ parseValue(arrayChange.from) }} <v-icon x-small>mdi-arrow-right</v-icon>{{ parseValue(arrayChange.to) }}&nbsp;</span>
          <span v-else-if="arrayChange.array.length == 0" class="font-weight-regular text--disabled">[ No Visible Changes ]&nbsp;</span>
          <span v-else>
            [&nbsp;
              <recursive-diff-content :diffContent="arrayChange.array" />
            ]&nbsp;
          </span>
        </span>
      </span>
    </span>
  </span>
</template>

<script>
export default {
    name: 'ResursiveDiffContent',
    props: ['diffContent'],
    methods: {
      nameLabel(stateName) {
        if (typeof stateName === 'string') {
          return stateName.replace(/_/g, ' ')
        } else if (typeof stateName === 'number') {
          return stateName
        } else if (typeof stateName === 'object') {
          return 'Object'
        }
        return typeof stateName
      },
      
      parseValue(value) {
        if (value === undefined) {
          return 'undefined'
        } else if (typeof(value) == 'object') {
          return 'Object (' + JSON.stringify(value) + ')'
        }
        return this.$helpers.getLabel(value)
      },
    }
  }
</script>