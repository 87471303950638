<template>
  <v-container class="pa-0" fluid>
    <v-row class="ma-0 ma-md-2">
      <v-col cols="12" sm="12">
        
        <div v-if="section == 'config'">
          <legacy-alert-config-box />
        </div>
        
        <div v-else>
          <alert-listing-box />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import AlertListingBox from '../components/alerts/AlertListing.vue'  
  import LegacyAlertConfigBox from '../components/alerts/LegacyAlertConfig.vue'
  
  export default {
    name: "Alerts",
    components: {
      AlertListingBox,
      LegacyAlertConfigBox
    },
    data() {
      return {
        section: this.$route.params.section,
      }
    },
    watch: {
      $route() {
        this.section = this.$route.params.section
      }
    },
    mounted() {
      
    },
    methods: {
      
    }
  }
</script>
