<template>
  <div>
    <v-alert outlined dense rounded class="state-chip ma-1" v-for="(change, index) in stateChanges" v-bind:key="index">
      <span class="text-capitalize font-weight-medium">{{ nameLabel(change.key) }}</span>:&nbsp;
      <span v-if="$helpers.isTimestamp(change.key)">{{ change.from | moment("YYYY/MM/DD hh:mm:ss a") }} <v-icon x-small>mdi-arrow-right</v-icon>{{ change.to | moment("YYYY/MM/DD hh:mm:ss a") }}</span>
      <span v-else-if="!change.array">{{ parseValue(change.from) }} <v-icon x-small>mdi-arrow-right</v-icon>{{ parseValue(change.to) }}</span>
      <span v-else-if="change.array.length == 0" class="font-weight-regular text--disabled">[ No Visible Changes ]</span>
      <span v-else>
        [&nbsp;
          <recursive-diff-content :diffContent="change.array" />
        ]&nbsp;
      </span>
    </v-alert>
  </div>
</template>

<script>
export default {
    name: 'StateChangeDiff',
    props: {
      eventData: {
        type: Object,
        default: () => {},
      },
    },
    computed: {
      stateChanges() {
        return this.$helpers.stateDiff(this.eventData.from_state, this.eventData.to_state)
      }
    },
    methods: {
      nameLabel(stateName) {
        if (typeof stateName === 'string') {
          return stateName.replace(/_/g, ' ')
        } else if (typeof stateName === 'number') {
          return stateName
        } else if (typeof stateName === 'object') {
          return 'Object'
        }
        return typeof stateName
      },
      
      parseValue(value) {
        if (value === undefined) {
          return 'undefined'
        } else if (typeof(value) == 'object') {
          return 'Object (' + JSON.stringify(value) + ')'
        }
        return this.$helpers.getLabel(value)
      },
    }
  }
</script>
<style lang="scss" scoped>
  .state-chip {
     font-size: 12px;
     line-height: 20px;
     padding: 3px 12px;
     min-height: 24px;
     align-items: center;
     display: inline-flex;
  }
  .theme--light.state-chip {
      border-color: rgba(0, 0, 0, 0.12) !important;
      color: rgba(0, 0, 0, 0.87) !important;
  }
  .theme--dark.state-chip {
    border-color: rgba(255, 255, 255, 0.12) !important;
    color: #FFFFFF !important;
  }
</style>